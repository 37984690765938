import React, { useState, useEffect, useRef } from "react";
import { Button, Row, Col, Form, Modal } from "react-bootstrap";
import ExportApi from "../api/ExportApi";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import validator from "validator";
import InputMask from "react-input-mask";
import states from "states-us";
import TawkTo from "tawkto-react";
import Switch from "@mui/material/Switch";
import { InputOTP } from 'antd-input-otp';
const NewProfile = () => {
  // const { id } = useParams();
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessage1, setErrorMessage1] = useState("");
  const [newPassword, setNewPassword] = useState();
  const [oldPassword, setOldPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [phone, setPhone] = useState();
  const [street, setStreet] = useState();
  const [subscribe, setSubscribe] = useState();
  const [validEmail, setValidEmail] = useState();
  const [city, setCity] = useState();
  const [country, setCountry] = useState();
  const [state, setState] = useState();
  const [postalCode, setPostalCode] = useState();
  const [showNameModal, setShowNameModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showPhoneModal, setShowPhoneModal] = useState(false);
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [latestPhoneNumber, setLatestPhoneNumber] = useState();
  const [buttonLoader, setButtonLoader] = useState(false);
  const [buttonPasswordLoader, setButtonPasswordLoader] = useState(false);
  const [buttonAddressLoader, setButtonAddressLoader] = useState(false);
  const [buttonPhoneLoader, setButtonPhoneLoader] = useState(false);
  const [validFirstName, setValidFirstName] = useState("");
  const [validLastName, setValidLastName] = useState("");
  const [validStreet, setValidStreet] = useState();
  const [validCity, setValidCity] = useState();
  const [validState, setValidState] = useState();
  const [validcountry, setValidCountry] = useState();
  const [validPostalCode, setValidPostalCode] = useState();
  const [validPhoneNumber, setValidPhoneNumber] = useState();
  const [is2FAEnabled, setIs2FAEnabled] = useState(false);
  const [showQRModal, setShowQRModal] = useState(false);
  const [secret, setSecret] = useState();
  const [qrcode, setQRCode] = useState();
  const [otp, setOTP] = useState('');
  const [notp, setNOTP] = useState('');
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [showinfoModal, setShowInfoModal] = useState(false);
  const [error, setSetError] = useState();

  // console.log(states)
  const token = localStorage.getItem("tokenuser");
  const userData = JSON.parse(token);
  const admintoken = localStorage.getItem("admin");
  const adminData = JSON.parse(admintoken);

  console.log(userData?.id, "userData321");
  console.log(adminData?.id, "adminData123");
  const modalRef = useRef(null);
  var id = userData.id ? userData?.id : adminData?.id;
  console.log(firstName, "firstName123");

  const handleSwitchChange = async () => {
    if (!is2FAEnabled) {
      // Enabling 2FA
      try {
        const response = await ExportApi.enable2FA(id);
        const { qrCodeUrl, secret } = response.data;

        // Store or use the secret and QR code URL
        setQRCode(qrCodeUrl);
        setSecret(secret);

        // Open the modal to show the QR code
        setShowQRModal(true);

        console.log("2FA Enabled");
      } catch (error) {
        console.error("Error enabling 2FA:", error);
        // Handle error, e.g., show a message to the user
        // Optionally, revert the switch state if the API call fails
        setIs2FAEnabled(false);
      }
    } else {
      // Disabling 2FA
      try {
        setShowOTPModal(true);
        // const resp = await ExportApi.disable2FA(id,otp);
        // setIs2FAEnabled(false);
        
        // toast.success(resp?.data?.message);
      } catch (error) {
        console.error("Error disabling 2FA:", error);
        toast.error(error);
      }
    }
  };
  const handleOTPSubmit = async () => {
    try {
      const resp = await ExportApi.verifyotp(otp, secret, id);
      console.log(resp, "handleOTPSubmit");
      if (resp.data.status == true) {
        toast.success(resp.data.message);
        setIs2FAEnabled(true);
        setShowQRModal(false);
      
        setOTP('')
      } else {
        toast.error(resp.data.message);
        setIs2FAEnabled(false);
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      toast.error("Internal server error");
    }
  };
  const handleOTPSubmitdisab = async () => {
    try {
      const resp = await ExportApi.disable2FA(id, notp);
      if (resp.data.status) {
        toast.success(resp.data.message);
        setIs2FAEnabled(false);
        setShowOTPModal(false);
        setNOTP('');
      } else {
        toast.error(resp.data.message);
      }
    } catch (err) {
      console.error(err);
      toast.error('Internal server error');
    }
  };

  //get the single user Data
  const handleSingleUserData = () => {
    const token = localStorage.getItem("tokenuser");
    const newData = JSON.parse(token);
    console.log(newData, "tokendata>>>>56");
    console.log(newData.id, "tokenid>>>>57");

    // // Configure headers for the request
    // const headers = {
    //   Authorization: `Bearer ${token}`, // Include token in the Authorization header
    //   'Content-Type': 'application/json', // Set content type to JSON
    // };
    ExportApi.getSingleUserData(id)
      .then((resp) => {
        console.log(resp, "data>>>>>>>>>>>>>getSingleUserData");
        if (resp.data.message == "user not found") {
          // window.dispatchEvent(new Event("Loginout"));
          console.log("user not found>>>>>>>>>>>>>>>>123");
        } else {
          let Data = resp?.data?.data;
          let number = Data?.phone;
          let numberString = number == null ? null : number?.toString();
          let maskedResponse = "";
          if (numberString == null) {
            maskedResponse = null;
          } else {
            maskedResponse = `(${numberString.slice(0, 3)})${numberString.slice(
              3,
              6
            )}-${numberString.slice(6)}`;
          }
         
          // console.log(maskedResponse);
          setFirstName(Data?.firstname);
          setValidFirstName(Data?.firstname);
          setLastName(Data?.lastname);
          setValidLastName(Data?.lastname);
          setEmail(Data?.email);
          setSubscribe(Data?.subscribe);
          setLoading(false);
          setLatestPhoneNumber(maskedResponse);
          setValidPhoneNumber(maskedResponse);
          setStreet(Data?.shipping_address?.street);
          setValidStreet(Data?.shipping_address?.street);
          setCity(Data?.shipping_address?.city);
          setValidCity(Data?.shipping_address?.city);
          setState(Data?.shipping_address?.state);
          setValidState(Data?.shipping_address?.state);
          setCountry(Data?.shipping_address?.country);
          setValidCountry(Data?.shipping_address?.country);
          setPostalCode(Data?.shipping_address?.postal_code);
          setValidPostalCode(Data?.shipping_address?.postal_code);
          if (resp?.data?.data?.twoFactorEnabled === true) {
            setIs2FAEnabled(true);
          }
        }
      })
      .catch((err) => console.log(err));
  };

  //update the user name
  // const handleUpdateName = () => {
  //   if (validFirstName.trim() === "" || validLastName.trim() === "") {
  //     toast.error("Name fields cannot be empty or contain only spaces");
  //     return;
  //   }
  //   setButtonLoader(true);
  //   ExportApi.updateSingleUserName(id, validFirstName, validLastName)
  //     .then((resp) => {
  //       toast.success("Name Updated Successfully");
  //       setShowNameModal(false);
  //       setButtonLoader(false);
  //       handleSingleUserData();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };
  const validateName = (name) => {
    const nameRegex = /^[A-Za-z\s]+$/;
    return nameRegex.test(name);
};

const handleUpdateName = () => {
  // Trim whitespace from the inputs
  const trimmedFirstName = validFirstName.trim();
  const trimmedLastName = validLastName.trim();

  let errorMessage = "";

  // Check if either name field is empty or contains only spaces
  if (trimmedFirstName === "" || trimmedLastName === "") {
    errorMessage = "Name fields cannot be empty.";
  } else if (!validateName(trimmedFirstName) && !validateName(trimmedLastName)) {
    errorMessage += " First name and Last name must only contain letters. ";
  }
    else {
    if (!validateName(trimmedFirstName)) {
      errorMessage += "First name must only contain letters. ";
    }

    // Validate last name
    if (!validateName(trimmedLastName)) {
      errorMessage += "Last name must only contain letters. ";
    }

  }

  // Check if both first name and last name are the same
  if (trimmedFirstName === trimmedLastName) {
    errorMessage += "First name and last name cannot be the same. ";
  }

  // Ensure the new name is different from the current name
  if (trimmedFirstName === firstName && trimmedLastName === lastName) {
    errorMessage += "New name is the same as the current name. ";
  }

  // If there are any errors, set them and return
  if (errorMessage) {
    setSetError(errorMessage.trim());
    return;
  }

  // Set loader and make API request to update the name
  setButtonLoader(true);
  ExportApi.updateSingleUserName(id, trimmedFirstName, trimmedLastName)
      .then((resp) => {
          toast.success("Name Updated Successfully.");
          setShowNameModal(false);
          setButtonLoader(false);
          handleSingleUserData();
          setSetError("");  // Clear error after successful update
      })
      .catch((err) => {
          console.log(err);
          toast.error("Failed to update name.");
          setButtonLoader(false);
      });
};


 

const closedUpdateName = () => {
  setShowNameModal(false);
  setSetError("");
};

useEffect(() => {
  const handleClickOutside = (event) => {
    // Check if modalRef is defined and contains is a function
    if (modalRef.current && modalRef.current.contains && !modalRef.current.contains(event.target)) {
      closedUpdateName();
   
    }
  };

  document.addEventListener('mousedown', handleClickOutside);
  return () => document.removeEventListener('mousedown', handleClickOutside);
}, []);
  //update the phone Number
  // const handleUpdatePhone = () => {
  //   setButtonPhoneLoader(true);
  //   if (!validPhoneNumber) {
  //     toast.error("Field Required");
  //     setButtonPhoneLoader(false);
  //     return;
  //   }
  //   let data = validPhoneNumber.replace(/\D/g, "");
  //   const isValidNumber = /^[1-9][0-9]*$/.test(data);
  //   if (isValidNumber) {
  //     if (data?.length > 9 && data?.length < 11) {
  //       ExportApi.updateSingleUserPhone(id, data)
  //         .then((resp) => {
  //           toast.success("Phone Updated Successfully");
  //           setShowPhoneModal(false);
  //           handleSingleUserData();
  //           setLatestPhoneNumber(resp.data.result.phone);
  //           setButtonPhoneLoader(false);
  //         })
  //         .catch((err) => {
  //           console.log(err);
  //         });
  //     } else {
  //       toast.error("Enter Valid Mobile Number");
  //       setButtonPhoneLoader(false);
  //     }
  //   } else {
  //     toast.error("Number Cannot be started with zero");
  //     setButtonPhoneLoader(false);
  //   }
  // };
  const handleUpdatePhone = async () => {
    setButtonPhoneLoader(true);
  
    // Check if the phone number field is valid
    if (!validPhoneNumber || typeof validPhoneNumber !== 'string') {
      toast.error("Field Required");
      setButtonPhoneLoader(false);
      return;
    }
  
    // Sanitize and validate phone number
    let data = validPhoneNumber.replace(/\D/g, "");
    const isValidNumber = /^[1-9][0-9]*$/.test(data);
  
    if (!isValidNumber) {
      toast.error("Number cannot start with zero");
      setButtonPhoneLoader(false);
      return;
    }
  
    if (data.length <= 9 || data.length >= 11) {
      toast.error("Enter Valid Mobile Number");
      setButtonPhoneLoader(false);
      return;
    }
  
    // Check if the new phone number is the same as the current phone number
    if (data === (latestPhoneNumber ? latestPhoneNumber.replace(/\D/g, "") : "")) {
      toast.error("New phone number is the same as the current phone number");
      setButtonPhoneLoader(false);
      setShowPhoneModal(false);
      return;
    }
  
    // Proceed with API call to update phone number
    try {
      const resp = await ExportApi.updateSingleUserPhone(id, data);
      toast.success("Phone Updated Successfully");
      setShowPhoneModal(false);
      handleSingleUserData();
      setLatestPhoneNumber(resp.data.result.phone);
    } catch (err) {
      console.error(err);
      toast.error("Failed to update phone number");
    } finally {
      setButtonPhoneLoader(false);
    }
  };
  
  

  //update the address
  // const handleUpdateAddress = () => {
  //   setButtonAddressLoader(true);

  //   if (
  //     !validStreet?.trim() ||
  //     !validCity?.trim() ||
  //     !validState?.trim() ||
  //     !validcountry?.trim()
  //   ) {
  //     toast.error("Address fields cannot be empty or not contain spaces");
  //     setButtonAddressLoader(false);
  //     return;
  //   }

  //   const postalCodeRegex = /^\d{5}(-\d{4})?$/; // Postal code can be 5 digits or 5 digits followed by - and 4 more digits

  //   if (!postalCodeRegex.test(validPostalCode)) {
  //     toast.error(
  //       'Postal Code must be 5 digits or in the format "12345" or "12345-6789"'
  //     );
  //     setButtonAddressLoader(false);
  //     return;
  //   }
  //   ExportApi.updateSingleUserData(
  //     id,
  //     validStreet,
  //     validCity,
  //     validState,
  //     validcountry,
  //     validPostalCode
  //   )

  //     .then((resp) => {
  //       let Data = resp?.data?.result;

  //       console.log(Data, "Data>>>123");
  //       setPostalCode(Data?.shipping_address?.postal_code);
  //       setCountry(Data?.shipping_address?.country);
  //       setState(Data?.shipping_address?.state);
  //       setCity(Data?.shipping_address?.city);
  //       setStreet(Data?.shipping_address?.street);
  //       //localStorage.setItem("useraddress", JSON.stringify(validStreet));
  //       localStorage.setItem("useraddress", true);
  //       toast.success("Address Updated Successfully");
  //       setShowAddressModal(false);
  //       setButtonAddressLoader(false);
  //       handleSingleUserData();
  //       return
  //       window.location.reload();
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  const handleUpdateAddress = () => {
    setButtonAddressLoader(true);

    if (
      !validStreet?.trim() ||
      !validCity?.trim() ||
      !validState?.trim() ||
      !validcountry?.trim()
    ) {
      toast.error("Address fields cannot be empty or contain only spaces");
      setButtonAddressLoader(false);
      return;
    }

    const postalCodeRegex = /^\d{5}(-\d{4})?$/; // Postal code can be 5 digits or 5 digits followed by - and 4 more digits

    if (!postalCodeRegex.test(validPostalCode)) {
      toast.error(
        'Postal Code must be 5 digits or in the format "12345" or "12345-6789"'
      );
      setButtonAddressLoader(false);
      return;
    }

    const currentAddress = {
      street: street,
      city: city,
      state: state,
      country: country,
      postalCode: postalCode,
    };

    const newAddress = {
      street: validStreet,
      city: validCity,
      state: validState,
      country: validcountry,
      postalCode: validPostalCode,
    };

    // Check if the new address is the same as the current address
    if (
      currentAddress.street === newAddress.street &&
      currentAddress.city === newAddress.city &&
      currentAddress.state === newAddress.state &&
      currentAddress.country === newAddress.country &&
      currentAddress.postalCode === newAddress.postalCode
    ) {
      toast.error("New address is the same as the current address");
      setButtonAddressLoader(false);
      setShowAddressModal(false);
      return;
    }

    ExportApi.updateSingleUserData(
      id,
      newAddress.street,
      newAddress.city,
      newAddress.state,
      newAddress.country,
      newAddress.postalCode
    )
      .then((resp) => {
        let Data = resp?.data?.result;

        console.log(Data, "Data>>>123");
        setPostalCode(Data?.shipping_address?.postal_code);
        setCountry(Data?.shipping_address?.country);
        setState(Data?.shipping_address?.state);
        setCity(Data?.shipping_address?.city);
        setStreet(Data?.shipping_address?.street);
        localStorage.setItem("useraddress", true);
        toast.success("Address Updated Successfully");
        setShowAddressModal(false);
        setButtonAddressLoader(false);
        handleSingleUserData();
      })
      .catch((err) => {
        console.log(err);
        toast.error("Failed to update address");
        setButtonAddressLoader(false);
      });
  };

  //for the subscribe
  const handleSubscribe = (data) => {
    setSubscribe(data);
    ExportApi.handleSubscribe(id, data)
      .then((resp) => {
        let data = resp.data;
        handleSingleUserData();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //update the password
  const handleUpdatePassword = () => {
    if (errorMessage || errorMessage1) {
      toast.error("Please fill the Password with the format");
      return;
    }

    if (!newPassword || !oldPassword || !confirmPassword) {
      toast.error("Please Fill all password fields");
      return;
    }

    if (
      newPassword.trim() === "" ||
      oldPassword.trim() === "" ||
      confirmPassword.trim() === ""
    ) {
      toast.error("Password fields cannot be empty or contain only spaces");
      return;
    }

    setButtonPasswordLoader(true);
    if (newPassword == confirmPassword) {
      ExportApi.updatePassword(id, newPassword, oldPassword)
        .then((resp) => {
          if (resp.data.message == "Password Not Matched") {
            toast.error("Old Password Not Matched");
            setButtonPasswordLoader(false);
          } else {
            toast.success("Password Updated Successfully");
            setShowPasswordModal(false);
            setButtonPasswordLoader(false);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      toast.error("Password Not Matched");
      setButtonPasswordLoader(false);
    }
  };
  const handleNewPassword = (value) => {
    let isValid = true;
    let message = "";

    if (value.length < 8) {
      isValid = false;
      message += "Password must contain at least 8 characters. ";
    }
    if (!/[a-z]/.test(value)) {
      isValid = false;
      message += "Password must contain at least 1 lowercase letter. ";
    }
    if (!/[A-Z]/.test(value)) {
      isValid = false;
      message += "Password must contain at least 1 uppercase letter. ";
    }
    if (!/\d/.test(value)) {
      isValid = false;
      message += "Password must contain at least 1 number. ";
    }
    if (!/[@$!%*?&]/.test(value)) {
      isValid = false;
      message += "Password must contain at least 1 special character. ";
    }

    if (isValid) {
      setErrorMessage("");
      setNewPassword(value);
    } else {
      setErrorMessage(message);
    }
  };
  //validation for the password
  // const handleNewPassword = (value) => {
  //   if (
  //     validator.isStrongPassword(value, {
  //       minLength: 8,
  //       minLowercase: 1,
  //       minUppercase: 1,
  //       minSymbols: 1,
  //       minNumbers: 1,
  //     })
  //   ) {
  //     setErrorMessage("");
  //     setNewPassword(value);
  //   } else {
  //     setErrorMessage(
  //       "Passsword Contains min. 1 lowercase 1 uppercase 1 number 1 special character"
  //     );
  //   }
  // };

  //validation for the confirm password
  // const handleConfirmPassword = (value) => {
  //   if (
  //     validator.isStrongPassword(value, {
  //       minLength: 8,
  //       minLowercase: 1,
  //       minUppercase: 1,
  //       minSymbols: 1,
  //       minNumbers: 1,
  //     })
  //   ) {
  //     setErrorMessage1("");
  //     setConfirmPassword(value);
  //   } else {
  //     setErrorMessage1(
  //       "Passsword Contains min. 1 lowercase 1 uppercase 1 number 1 special character"
  //     );
  //   }
  // };

  const handleConfirmPassword = (value) => {
    let isValid = true;
    let message = "";

    if (value !== newPassword) {
      isValid = false;
      message += "Passwords do not match. ";
    }

    if (isValid) {
      setErrorMessage1("");
      setConfirmPassword(value);
    } else {
      setErrorMessage1(message);
    }
  };

  // validation for the number
  const handleValidPhoneNumber = (value) => {
    setValidPhoneNumber(value);
    // localStorage.setItem("phoneNumber", value);
  };

  useEffect(() => {
    handleSingleUserData();
  }, []);

  // useEffect(() => {
  //   const storedData = localStorage.getItem("tokenuser");
  //   console.log(storedData,"storedData>>>>>14");
  //   const userData = storedData ? JSON.parse(storedData) : {};
  //   console.log(userData, "userData>>>");
  //   console.log(userData.id, "userData>>>");
  //   const firstname = userData.firstname;
  //   const email = userData.email;
  //   console.log(firstname, email, "userData>>>");
  //   const tawkToPropertyId = '6583dce370c9f2407f8220c3';
  //   const tawkToKey = '1hi5hdmtr';

  //   const tawkToInstance = new TawkTo(tawkToPropertyId, tawkToKey);

  //   // Set visitor information
  //   tawkToInstance.setAttributes({
  //     name: firstname,
  //     email: email,
  //   });

  // for chat started apis
  // tawkToInstance.onChatStarted(() => {
  //   console.log("chatstartedd>>>")
  //   const storedData = localStorage.getItem("tokenuser");
  //   const userData = storedData ? JSON.parse(storedData) : {};
  //   const chatData = {
  //     timestamp: new Date(),
  //     userId: userData.id,
  //   };
  //   console.log('dataaaa>>>>', chatData);

  //   ExportApi.addCreateChat(chatData)
  //     .then(res => {
  //       console.log('Chat created successfully:', res);
  //     })
  //     .catch(error => {
  //       console.error('Error creating chat:', error);
  //     });
  //   console.log('After API call');

  // });

  // },[]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100vh",
        }}
      >
        <div className="loader-icon" style={{ marginBlock: "80px" }}>
          <i className="fa-solid fa-spinner fa-spin-pulse"></i>
        </div>
      </div>
    );
  }

  window.addEventListener("Loginout", () => {
    localStorage.clear();
    navigate("/");
  });
  const handleOTPChange = (value) => {
    // Join array values into a single string
    setOTP(value.join(''));
  };

  const handleNOTPChange = (value) => {
    // Join array values into a single string
    setNOTP(value.join(''));
  };

 
  return (
    <div>
      <Row>
        <Col xl={8} lg={12} md={12}>
          <div className="profile_box">
            <h2 className="fs-1 fw-300 mb-5 mt-3">Profile</h2>
            <div className="line_one_box d-flex justify-content-start align-items-center border-bottom mt-3">
              <p className="fs-5 fw-bold m-0 w-25">Name:</p>
              <p className="fs-5 fw-bold m-0 w-75">{`${firstName} ${lastName}`}</p>
              <Button
                className="bg-transparent border-0 text-dark fs-5"
                onClick={() => setShowNameModal(true)}
              >
                Edit
              </Button>
            </div>
            <div className="line_one_box d-flex justify-content-start align-items-center border-bottom mt-3">
              <p className="fs-5 fw-bold m-0 w-25">Email Address:</p>
              <p className="fs-5 fw-bold m-0 w-75 break-email">{email}</p>
              {/* <Button className='bg-transparent border-0 text-dark fs-5' onClick={() => setShowEmailModal(true)}>Edit</Button> */}
            </div>
            <div className="line_one_box d-flex justify-content-start align-items-center border-bottom mt-3">
              <p className="fs-5 fw-bold m-0 w-25">Password:</p>
              <p className="fs-5 fw-bold m-0 w-75">*********</p>
              <Button
                className="bg-transparent border-0 text-dark fs-5"
                onClick={() => setShowPasswordModal(true)}
              >
                Edit
              </Button>
            </div>
            <div className="line_one_box d-flex justify-content-start align-items-center border-bottom mt-3">
              <p className="fs-5 fw-bold m-0 w-25">Phone:</p>
              <p className="fs-5 fw-bold m-0 w-75">{latestPhoneNumber}</p>
              <Button
                className="bg-transparent border-0 text-dark fs-5"
                onClick={() => setShowPhoneModal(true)}
              >
                Edit
              </Button>
            </div>
            <div className="line_one_box d-flex justify-content-start align-items-center border-bottom mt-3">
              <p className="fs-5 fw-bold m-0 w-25">Shipping Address : </p>
              <p className="fs-5 fw-bold m-0 w-75">
                {street ? street + "." : ""} {city ? city + "," : ""}{" "}
                {state ? state : ""} {postalCode ? postalCode + "," : ""}
              </p>
              <Button
                className="bg-transparent border-0 text-dark fs-5"
                onClick={() => setShowAddressModal(true)}
              >
                Edit
              </Button>
            </div>
            <br></br>
            {/* <div className="switch-container">
              <Switch
                checked={is2FAEnabled}
                onChange={handleSwitchChange}
                inputProps={{ "aria-label": "controlled" }}
              />

              <p className="fs-5 fw-bold m-0 w-25">2FA is {is2FAEnabled ? "Enabled" : "Disabled"}</p>
            </div> */}
            {/* {showQRModal && (
              <div className="otp-modal">
                <div className="otp-modal-content">
                  <h3>Verify OTP</h3>
                  <p>
                    Scan the QR code with your authenticator app and enter the
                    OTP.
                  </p>
                  <img src={qrcode} alt="QR Code" />
                  <input
                    type="text"
                    placeholder="Enter OTP"
                    value={otp}
                    onChange={(e) => setOTP(e.target.value)}
                  />
                  <button onClick={handleOTPSubmit}>Verify OTP</button>
                </div>
              </div>
            )} */}

            <div className="Custom_check_box d-flex justify-content-start align-items-center  mt-3">
              <Form>
                <div key="default-checkbox" className="mb-3">
                  <Form.Check
                    className="fs-5 fw-bold mt-3"
                    type="checkbox"
                    checked={subscribe ? true : false}
                    onChange={(e) => handleSubscribe(e.target.checked)}
                    label="Subscribe For Exclusive E-mail offers and discounts"
                  />
                </div>
              </Form>
            </div>
          </div>
        </Col>
        <Col lg={6}></Col>
      </Row>
   {/* Start QR Modal */}
      <Modal
        show={showQRModal}
        onHide={() => setShowQRModal(false)}
        backdrop="static"  
        keyboard={false}  
        size="lg" 
      >
        <Modal.Header closeButton>
          <Modal.Title>Set Up Authenticator App</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{
      maxHeight: '400px', // Adjust height as needed
      overflowY: 'auto',  // Enable vertical scrolling
    }}>       
           <p className="qrspace"> <b> STEP ONE</b></p>
          <p className="qrspace">Download/Use any authenticator application (Google Authenticator, Microsoft Authenticator,etc.)on your
            mobile device
          </p>
          <p className="qrspace"> <b> STEP TWO</b></p>
          <p>On your authenticator application, tap the "+" icon and then select "Scan QR code" to scan the 
            QR code below
          </p>
          <img src={qrcode} alt="QR Code" height="100px" width="100px" />
          <p className="qrspace"> <b> STEP THREE</b></p>
          <p className="qrspace">Once this code has been scanned, enter the 6-digit code generateed by the app</p>
          <Form.Group className="mb-3"  style={{ display: 'flex', justifyContent: 'left' }} >
          <InputOTP
          autoFocus
          value={otp.split('')} 
          onChange={handleOTPChange}
        length={6}
        style={{ height: '50px' }}
      />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowQRModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleOTPSubmit}>
            Verify OTP
          </Button>
        </Modal.Footer>
      </Modal>
    {/* End QR Modal */}

   

      {/* OTP Verification Modal */}
      <Modal
        show={showOTPModal}
        onHide={() => setShowOTPModal(false)}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Verify OTP</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Enter the OTP to disable 2FA.</p>
          <InputOTP
            type="text"
            value={notp.split('')} 
            length={6}
        style={{ height: '50px' }}
            onChange={handleNOTPChange}
            className="form-control"
          />
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary" onClick={handleOTPSubmitdisab}>
            Verify OTP
          </button>
          <button className="btn btn-secondary" onClick={() => setShowOTPModal(false)}>
            Cancel
          </button>
        </Modal.Footer>
      </Modal>

      {/* Start Name Modal */}
      <Modal
        show={showNameModal}
        onHide={() => setShowNameModal(false)}
        backdrop={true}
        ref={modalRef}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Name</Modal.Title>
        </Modal.Header>
        <Modal.Body className="scrollable-modal-body">
          <Form.Group className="mb-3">
            <Form.Label>First Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Your First Name"
              value={validFirstName}
              onChange={(e) => setValidFirstName(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Last Name</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Your Last Name"
              value={validLastName}
              onChange={(e) => setValidLastName(e.target.value)}
            />
          </Form.Group>
          {error && <div className="alert alert-danger">{error}</div>}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" 
          // onClick={() => setShowNameModal(false)}
          onClick={closedUpdateName}
          
          >
            Close
          </Button>
          <Button
            variant="primary"
            disabled={buttonLoader}
            onClick={handleUpdateName}
          >
            {buttonLoader ? "Please Wait" : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* End Name Modal */}

      {/* Start Password Modal */}
      <Modal
        show={showPasswordModal}
        onHide={() => setShowPasswordModal(false)}
        backdrop={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Password</Modal.Title>
        </Modal.Header>
        <Modal.Body className="scrollable-modal-body">
          <Form.Group className="mb-3">
            <Form.Label>Old Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter Your Old Password"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>New Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter Your Password"
              onChange={(e) => handleNewPassword(e.target.value)}
            />
          </Form.Group>
          {errorMessage === "" ? null : (
            <span
              style={{
                fontWeight: "bold",
                color: "red",
              }}
            >
              {errorMessage}
            </span>
          )}
          <Form.Group className="mb-3">
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              placeholder="Enter Your Password"
              onChange={(e) => handleConfirmPassword(e.target.value)}
            />
          </Form.Group>
          {errorMessage1 === "" ? null : (
            <span
              style={{
                fontWeight: "bold",
                color: "red",
              }}
            >
              {errorMessage1}
            </span>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowPasswordModal(false)}
          >
            Close
          </Button>
          <Button
            variant="primary"
            disabled={buttonPasswordLoader}
            onClick={handleUpdatePassword}
          >
            {buttonPasswordLoader ? "Please Wait..." : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* end Password Modal */}

      {/* Start Phone Modal */}
      <Modal
        show={showPhoneModal}
        onHide={() => setShowPhoneModal(false)}
        backdrop={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Phone</Modal.Title>
        </Modal.Header>
        <Modal.Body className="scrollable-modal-body">
          <Form.Group className="mb-3">
            <Form.Label>Phone</Form.Label>
            <InputMask
              className="form-control"
              mask="(999) 999-9999"
              placeholder="(123) 456-7890"
              type="tel"
              value={validPhoneNumber}
              onChange={(e) => handleValidPhoneNumber(e.target.value)}
            />
            {/* <Form.Control type="number" placeholder="Enter Your Phone" pattern="[0-9]{10}" value={phone} onChange={(e) => setPhone(e.target.value)} /> */}
            {/* <p style={{ color: "red" }}>{phone?.length == 10 ? '' : 'Invalid mobile number'}</p> */}
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowPhoneModal(false)}>
            Close
          </Button>
          <Button
            variant="primary"
            disabled={buttonPhoneLoader}
            onClick={handleUpdatePhone}
          >
            {buttonPhoneLoader ? "Please Wait" : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* End Phone Modal */}

      {/* Start Shipping Address */}
      <Modal
        show={showAddressModal}
        onHide={() => setShowAddressModal(false)}
        backdrop={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update Address</Modal.Title>
        </Modal.Header>
        <Modal.Body className="scrollable-modal-body">
          <Form.Group className="mb-3">
            <Form.Label>Address</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Your Address"
              value={validStreet}
              onChange={(e) => setValidStreet(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>City</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Your City"
              value={validCity}
              onChange={(e) => setValidCity(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>State</Form.Label>
            {/* <Form.Control type="text" placeholder="Enter Your State" value={validState} onChange={(e) => setValidState(e.target.value)} /> */}
            <Form.Select
              aria-label="Default select example"
              onChange={(e) => setValidState(e.target.value)}
            >
              {validState ? (
                <option selected>{validState}</option>
              ) : (
                <option selected>Select State</option>
              )}
              {states?.map((item) => {
                return <option value={item?.abbreviation}>{item?.name}</option>;
              })}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Country</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Your Country"
              value={validcountry}
              onChange={(e) => setValidCountry(e.target.value)}
            />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Postal Code</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Your Postal Code"
              value={validPostalCode}
              onChange={(e) => setValidPostalCode(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowAddressModal(false)}
          >
            Close
          </Button>
          <Button
            variant="primary"
            disabled={buttonAddressLoader}
            onClick={handleUpdateAddress}
          >
            {buttonAddressLoader ? "Please Wait..." : "Save"}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* End Shipping Address */}
      {/* <ToastContainer /> */}
    </div>
  );
};


{/* <Modal
show={showinfoModal}
onHide={() => setShowInfoModal(false)}
backdrop="static" // Disables clicking outside to close
keyboard={false} // Disables closing with keyboard escape
>
<Modal.Header closeButton>
  <Modal.Title>Details About Google Authenticator (2FA)</Modal.Title>
</Modal.Header>
<Modal.Body   style={{
maxHeight: '400px', // Adjust height as needed
overflowY: 'auto',  // Enable vertical scrolling
}}>

<p>
Google Authenticator adds an extra layer of security to your online accounts by requiring a second step of verification when you sign in. 
In addition to your password, you'll also need to enter a time-based code generated by the Google Authenticator app on your phone. 
This helps ensure that even if someone steals your password, they cannot access your account without the code from your app.
</p>
<p>
To use Google Authenticator:
</p>
<ol>
<li>Download the Google Authenticator app from the <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&pcampaignid=web_share" target="_blank" rel="noopener noreferrer">Google Play Store</a> for Android or the <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank" rel="noopener noreferrer">App Store</a> for iOS.</li>
<li>Open the app and follow the instructions to add a new account. You may need to scan a QR code or enter a setup key provided by the website or app you are securing.</li>
<li>Once set up, the app will generate a new 6-digit code every 30 seconds. Enter this code when prompted during the sign-in process.</li>
</ol>
<p>
<strong>Benefits of Using 2FA:</strong>
</p>
<ul>
<li>Increased security by requiring both your password and the code from the authenticator app.</li>
<li>Protection against password theft and unauthorized access to your accounts.</li>
<li>Enhanced safety for sensitive information and online transactions.</li>
</ul>
<p>
If you encounter any issues setting up Google Authenticator or have questions about 2FA, please contact our support team for assistance.
</p>


</Modal.Body>
<Modal.Footer>
  <Button variant="secondary" onClick={() => setShowInfoModal(false)}>
    Close
  </Button>
 
</Modal.Footer>
</Modal> */}

export default NewProfile;
