import React, { useEffect, useState, useCallback, useRef } from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { IconButton } from "@mui/material";
import ExportApi from "../../../api/ExportApi";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  Font,
  Bold,
  Italic,
  Strikethrough,
  Subscript,
  Superscript,
  Underline,
  Link,
  List,
  BlockQuote,
  Heading,
  Image,
  MediaEmbed,
  Table,
  TableToolbar,
  Undo,
  TableCellProperties,
  TableProperties,
} from "ckeditor5";
 
import "../HelpManagement.css";
import Default from "../../../assets/img/default.jpg";

export default function TopicList() {
  let imageUrl = process.env.REACT_APP_IMAGEBASEURL;
  const [count, setCount] = useState();
  const [categoryData, setCategoryData] = useState();
  const [categoryData1, setCategoryData1] = useState();
  const [showModal, setShowModal] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const [updateLogo, setUpdateLogo] = useState();
  const [originalData, setOriginalData] = useState(null);

  const [loader, setLoader] = useState(false);

  const [error, setError] = useState({
    name: "",
    short_description: "",
    description: "",
    logo: "",
  });

  const handleClose = () => {
    setUpdateLogo();
    setShowModal(false);
  };

  // const handleSingleData = (e, data) => {
  //     const filteredObject = categoryData.filter(obj => obj._id === data);
  //     setSelectedData(filteredObject[0]);
  //     console.log(filteredObject);
  //     setShowModal(true);
  // }
  const handleSingleData = (e, data) => {
    const filteredObject = categoryData.filter((obj) => obj._id === data);
    setSelectedData(filteredObject[0]);
    setOriginalData(filteredObject[0]); // Save the original data for comparison
    setShowModal(true);
  };

  const handleEditSubmit = () => {
    // Initial error handling
    if (!selectedData?.name) {
      setError((prevError) => ({
        ...prevError,
        name: "Topic name is Required",
      }));
      return;
    } else {
      setError((prevError) => ({
        ...prevError,
        name: "",
      }));
    }

    if (!selectedData?.short_description) {
      setError((prevError) => ({
        ...prevError,
        short_description: "Topic short description is Required",
      }));
      return;
    } else {
      setError((prevError) => ({
        ...prevError,
        short_description: "",
      }));
    }

    if (!selectedData?.description) {
      setError((prevError) => ({
        ...prevError,
        description: "Topic body description is Required",
      }));
      return;
    } else {
      setError((prevError) => ({
        ...prevError,
        description: "",
      }));
    }

    if (selectedData?.ismain && !(updateLogo || selectedData?.logo)) {
      setError((prevError) => ({
        ...prevError,
        logo: "Topic Icon is Required",
      }));
      return;
    } else {
      setError((prevError) => ({
        ...prevError,
        logo: "",
      }));
    }

    // Create FormData object
    const formData = new FormData();
    formData.append("id", selectedData?._id);

    // Append only the fields that have been updated
    if (selectedData?.name !== originalData?.name) {
      formData.append("name", selectedData?.name);
    }

    if (selectedData?.short_description !== originalData?.short_description) {
      formData.append("short_description", selectedData?.short_description);
    }

    if (selectedData?.description !== originalData?.description) {
      formData.append("description", selectedData?.description);
    }

    if (selectedData?.topic_categotyId !== originalData?.topic_categotyId) {
      formData.append("categoryId", selectedData?.topic_categotyId);
    }

    if (selectedData?.ismain !== originalData?.ismain) {
      formData.append("main", selectedData?.ismain);
    }

    if (updateLogo) {
      formData.append("image", selectedData.logo);
    }

    // Send the data
    setLoader(true);
    ExportApi.updateHelpTopic(formData)
      .then((resp) => {
        const data = resp?.data;
        if (data?.status === 201) {
          toast.success(data?.message);
          setCount(1);
          getTopicData();
          setShowModal(false);
          setUpdateLogo(null);
          setLoader(false);
        } else {
          setShowModal(false);
          toast.error(data?.message);
          setLoader(false);
          setCount(1);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
        setShowModal(false);
      });
    setCount(1);
  };

  const handleSingleDelete = (data) => {
    const userConfirmed = window.confirm("Do you want to proceed?");
    if (userConfirmed) {
      ExportApi.deleteHelpTopic(data)
        .then((resp) => {
          console.log(resp?.data);
          const data = resp?.data;
          if (data?.status == 201) {
            toast.success(data?.message);
            setCount(1);
            getTopicData();
          } else {
            toast.error(data?.message);
            setCount(1);
          }
        })
        .catch((err) => console.log(err));
      setCount(1);
    } else {
      console.log("cancel");
    }
  };

  useEffect(() => {
    getTopicData();
  }, []);

  const getTopicData = () => {
    ExportApi.getHelpTopics()
      .then((resp) => {
        console.log(resp?.data);
        const data = resp?.data;
        if (data?.status == 201) {
          console.log(data?.detail);
          setCategoryData(data?.detail);
          setCount(1);
        } else {
          toast.error(data?.message);
          setCount(1);
        }
      })
      .catch((err) => console.log(err));
    setCount(1);

    ExportApi.getHelpData("")
      .then((resp) => {
        console.log(resp?.data);
        const data = resp?.data;
        if (data?.status == 201) {
          console.log(data?.detail);
          setCategoryData1(data?.detail);
          setCount(1);
        } else {
          toast.error(data?.message);
          setCount(1);
        }
      })
      .catch((err) => console.log(err));
    setCount(1);
  };

  return (
    <div className="shipping mt-5">
      <h2>Topic List</h2>
      <table striped className="align-middle mt-4">
        <thead>
          <tr>
            <th></th>
            <th></th>
            <th>Name</th>
            <th>Short Description</th>
            <th>Body Description</th>
            <th>Main</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {categoryData?.length > 0 ? (
            categoryData.map((data, i) => {
              return (
                <tr key={i}>
                  <td>{i + 1}</td>
                  <td>
                    {data?.logo ? (
                      <img
                        src={`${data?.logo}`}
                        // src={
                        //   data?.logo?.includes("http")
                        //     ? data?.logo
                        //     : `${imageUrl}${data?.logo}`
                        // }
                        style={{ width: "100px" }}
                      />
                    ) : (
                      <img src={Default} style={{ width: "100px" }} />
                    )}
                  </td>
                  <td>
                    <p className="line-clamp">{data?.name}</p>
                  </td>
                  <td>
                    <p className="line-clamp">{data?.short_description}</p>
                  </td>
                  <td>
                    {/* <p className="line-clamp" dangerouslySetInnerHTML={{ __html: data?.description }}></p> */}
                    <p className="line-clamp">
                      {data?.description?.replace(/<\/?[^>]+(>|$)/g, "")}
                    </p>
                  </td>
                  <td>{data.ismain ? "Yes" : "No"}</td>
                  <td>
                    <button
                      className="btn d-flex align-items-center"
                      onClick={(e) => {
                        handleSingleData(e, data?._id);
                      }}
                    >
                      Edit
                      <span>
                        <svg
                          fill="#000000"
                          version="1.1"
                          id="Capa_1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 494.936 494.936"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            {" "}
                            <g>
                              {" "}
                              <g>
                                {" "}
                                <path d="M389.844,182.85c-6.743,0-12.21,5.467-12.21,12.21v222.968c0,23.562-19.174,42.735-42.736,42.735H67.157 c-23.562,0-42.736-19.174-42.736-42.735V150.285c0-23.562,19.174-42.735,42.736-42.735h267.741c6.743,0,12.21-5.467,12.21-12.21 s-5.467-12.21-12.21-12.21H67.157C30.126,83.13,0,113.255,0,150.285v267.743c0,37.029,30.126,67.155,67.157,67.155h267.741 c37.03,0,67.156-30.126,67.156-67.155V195.061C402.054,188.318,396.587,182.85,389.844,182.85z"></path>{" "}
                                <path
                                  d="M483.876,20.791c-14.72-14.72-38.669-14.714-53.377,0L221.352,229.944c-0.28,0.28-3.434,3.559-4.251,5.396l-28.963,65.069 c-2.057,4.619-1.056,10.027,2.521,13.6c2.337,2.336,5.461,3.576,8.639,3.576c1.675,0,3.362-0.346,4.96-1.057l65.07-28.963 c1.83-0.815,5.114-3.97,5.396-4.25L483.876,74.169c7.131-7.131,11.06-16.61,11.06-26.692 C494.936,37.396,491.007,27.915,483.876,20.791z M466.61,56.897L257.457,266.05c-0.035,0.036-0.055,0.078-0.089,0.107 l-33.989,15.131L238.51,247.3c0.03-0.036,0.071-0.055,0.107-0.09L447.765,38.058c5.038-5.039,13.819-5.033,18.846,0.005 c2.518,2.51,3.905,5.855,3.905,9.4
                                14C470.516,51.036,469.127,54.38,466.61,56.897z"
                                ></path>{" "}
                              </g>{" "}
                            </g>{" "}
                          </g>
                        </svg>
                      </span>
                    </button>
                  </td>
                  <td>
                    <button
                      className="btn d-flex align-items-center"
                      onClick={(e) => {
                        handleSingleDelete(data?._id);
                      }}
                    >
                      Delete
                      <span>
                        <svg
                          fill="#000000"
                          version="1.1"
                          id="Capa_1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 494.936 494.936"
                        >
                          <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                          <g
                            id="SVGRepo_tracerCarrier"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></g>
                          <g id="SVGRepo_iconCarrier">
                            <g>
                              <g>
                                <path d="M389.844,182.85c-6.743,0-12.21,5.467-12.21,12.21v222.968c0,23.562-19.174,42.735-42.736,42.735H67.157 c-23.562,0-42.736-19.174-42.736-42.735V150.285c0-23.562,19.174-42.735,42.736-42.735h267.741c6.743,0,12.21-5.467,12.21-12.21 s-5.467-12.21-12.21-12.21H67.157C30.126,83.13,0,113.255,0,150.285v267.743c0,37.029,30.126,67.155,67.157,67.155h267.741 c37.03,0,67.156-30.126,67.156-67.155V195.061C402.054,188.318,396.587,182.85,389.844,182.85z"></path>{" "}
                                <path d="M483.876,20.791c-14.72-14.72-38.669-14.714-53.377,0L221.352,229.944c-0.28,0.28-3.434,3.559-4.251,5.396l-28.963,65.069 c-2.057,4.619-1.056,10.027,2.521,13.6c2.337,2.336,5.461,3.576,8.639,3.576c1.675,0,3.362-0.346,4.96-1.057l65.07-28.963 c1.83-0.815,5.114-3.97,5.396-4.25L483.876,74.169c7.131-7.131,11.06-16.61,11.06-26.692 C494.936,37.396,491.007,27.915,483.876,20.791z M466.61,56.897L257.457,266.05c-0.035,0.036-0.055,0.078-0.089,0.107 l-33.989,15.131L238.51,247.3c0.03-0.036,0.071-0.055,0.107-0.09L447.765,38.058c5.038-5.039,13.819-5.033,18.846,0.005 c2.518,2.51,3.905,5.855,3.905,9.414C470.516,51.036,469.127,54.38,466.61,56.897z"></path>{" "}
                              </g>
                            </g>
                          </g>
                        </svg>
                      </span>
                    </button>
                  </td>
                </tr>
              );
            })
          ) : (
            <h5 className="text-center">No Data Found</h5>
          )}
        </tbody>
      </table>
      <br></br>
      <br></br>
      <br></br>
      <br></br>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Details</Modal.Title>
        </Modal.Header>
        {/* <Modal.Body className='shipping-modal'> */}
        <Modal.Body className="scrollable-modal-body">
          <Form action="">
            <div className="input-container my-4">
              <label>Topic Name</label>
              <input
                type="text"
                className="form-control"
                placeholder={selectedData?.name}
                value={selectedData ? selectedData?.name : "test"}
                onChange={(e) =>
                  setSelectedData((prevData) => ({
                    ...prevData,
                    name: e.target.value,
                  }))
                }
              />
              {error?.name && <p style={{ color: "red" }}>{error?.name}</p>}
            </div>

            <div className="input-container my-4">
              <label>Topic Short description</label>
              <input
                type="text"
                className="form-control"
                placeholder={selectedData?.short_description}
                value={selectedData ? selectedData?.short_description : "test"}
                onChange={(e) =>
                  setSelectedData((prevData) => ({
                    ...prevData,
                    short_description: e.target.value,
                  }))
                }
              />
              {error?.short_description && (
                <p style={{ color: "red" }}>{error?.short_description}</p>
              )}
            </div>

            {/* <div className="input-container my-4"> */}
            <label>Topic Body Description</label>
            {/* <CKEditor
              editor={ClassicEditor}
              data={selectedData ? selectedData?.description : ""} // S
              onChange={(event, editor) => {
                const data = editor.getData();
                setSelectedData((prevData) => ({
                  ...prevData,
                  description: data,
                }));
              }}
            /> */}
               <CKEditor
                editor={ClassicEditor}
                data={selectedData ? selectedData?.description : ""}
                config={{
                  allowedContent: true, 
                  plugins: [
                    Font,
                    Bold,
                    Italic,
                    Link,
                    Strikethrough,
                    Subscript,
                    Underline,
                    List,
                    BlockQuote,
                    Heading,
                    Image,
                    MediaEmbed,
                    Table,
                    TableProperties,
                    TableCellProperties,
                    TableToolbar,
                    Undo 
                  ],
                  toolbar: {
                    items: [
                      "heading",
                      "|",
                      "bold",
                      "italic",
                      "Underline",
                      "Superscript",
                      "Subscript",
                      "Strikethrough",
                      "fontSize",
                      "fontColor",
                      "fontBackgroundColor",
                      "bulletedList",
                      "numberedList",
                      "|",
                      "indent",
                      "outdent",
                      "|",
                      "blockQuote",
                      "insertTable",
                      "|",
                      "undo",
                      "redo",
                      "mediaEmbed",
                      "imageUpload",
                      "link",
                      "fontFamily",
                      "tableColumn",
                      "tableRow",
                      "mergeTableCells",
                      "alignment",
                      "tableProperties",
                      "tableCellProperties",
                      
                    ],
                  },
                  mediaEmbed: {
                    previewsInData: true,
                  },
                  image: {
                    toolbar: [
                      "imageTextAlternative",
                      "|",
                      "imageStyle:full",
                      "imageStyle:side",
                    ],
                    styles: ["alignLeft", "alignCenter", "alignRight"],
                  },
                  fontFamily: {
                    options: ["Helvetica", "Open Sans"],
                  },
                  table: {
                    contentToolbar: [
                      "tableColumn",
                      "tableRow",
                      "mergeTableCells",
                      "alignment",
                      "tableProperties",
                      "tableCellProperties",
                    ],

                    
                  },
                 
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setSelectedData((prevData) => ({
                    ...prevData,
                    description: data,
                  }));
                }}
              />
            {/* </div> */}
            {error?.description && (
              <p style={{ color: "red" }}>{error?.description}</p>
            )}

            <Form.Group controlId="file">
              <Form.Label>Topic Icon</Form.Label>
              &nbsp; &nbsp; &nbsp;
              {/* {!updateLogo ? 
                        <img
                        src={selectedData?.logo?.includes('http') ? selectedData?.logo : `${imageUrl}${selectedData?.logo}`}
                         className="my-3"
                          style={{ width: "100px" }}/>
                        : 
                        <img src={updateLogo} style={{ width: "100px" }} className="my-3"/>
                    } */}
              {!updateLogo ? (
                selectedData?.logo && ( // Check if selectedData.logo exists
                  <img
                    src={
                      typeof selectedData.logo === "string"
                        ? selectedData.logo.includes("http")
                          ? selectedData.logo
                          : `${imageUrl}${selectedData.logo}`
                        : ""
                    }
                    className="my-3"
                    style={{ width: "100px" }}
                    alt="Topic Icon"
                  />
                )
              ) : (
                <img
                  src={updateLogo}
                  style={{ width: "100px" }}
                  className="my-3"
                  alt="Preview"
                />
              )}
              {/* <Form.Control
                        type="file"
                        accept=".jpg, .jpeg, .png"
                        onChange={(e) => {
                                 
                                const file = e.target.files[0];   
                            if (file) {
                                const reader = new FileReader();
                          
                                reader.onloadend = () => {
                              
                                  setUpdateLogo(reader.result);
                                };
                          
                                 
                                reader.readAsDataURL(file);
                              }
                        
                            setSelectedData((prevData) => 
                            ({...prevData,logo: file}))                 
                        }}                  
                    /> */}
              <Form.Control
                type="file"
                accept=".jpg, .jpeg, .png"
                onChange={(e) => {
                  const file = e.target.files[0];

                  if (file) {
                    // Check if the file type is an image
                    if (!file.type.startsWith("image/")) {
                      // If not an image, clear the file input and display an error message
                      e.target.value = null;
                      setUpdateLogo(null); // Clear the image preview
                      setSelectedData((prevData) => ({
                        ...prevData,
                        logo: null,
                      })); // Clear the logo in the form data
                      setError({
                        logo: "Only images (JPG, JPEG, PNG) are allowed",
                      });
                      return;
                    }

                    // If it's an image, set the image preview
                    const reader = new FileReader();
                    reader.onloadend = () => {
                      setUpdateLogo(reader.result);
                    };
                    reader.readAsDataURL(file);

                    // Update the logo in the form data
                    setSelectedData((prevData) => ({
                      ...prevData,
                      logo: file,
                    }));
                    setError({ logo: null }); // Clear any previous error message
                  }
                }}
              />
            </Form.Group>
            {error?.logo && <p style={{ color: "red" }}>{error?.logo}</p>}

            {/* Select box */}
            <Form.Group controlId="formSelect">
              <Form.Label>Select an option:</Form.Label>
              <Form.Control
                as="select"
                value={
                  selectedData?.topic_categotyId
                    ? selectedData?.topic_categotyId
                    : ""
                }
                onChange={(e) =>
                  setSelectedData((prevData) => ({
                    ...prevData,
                    topic_categotyId: e.target.value,
                  }))
                }
              >
                <option value="">Select an option</option>
                {categoryData1?.length > 0
                  ? categoryData1.map((item) => (
                      <option key={item?._id} value={item?._id}>
                        {item?.name}
                      </option>
                    ))
                  : ""}
              </Form.Control>
            </Form.Group>

            <Form.Group controlId="formCheckbox">
              <Form.Check
                type="checkbox"
                label="Main Topic"
                checked={selectedData?.ismain}
                onChange={() =>
                  setSelectedData((prevData) => ({
                    ...prevData,
                    ismain: !selectedData?.ismain,
                  }))
                }
              />
            </Form.Group>
          </Form>

          <div className="buttons d-flex justify-content-center mt-4">
            {error?.logo ? (
              <button className="btn btn-primary me-4">
                {loader ? "Saving..." : "Save"}
              </button>
            ) : (
              <button
                className="btn btn-primary me-4"
                onClick={handleEditSubmit}
              >
                {loader ? "Saving..." : "Save"}
              </button>
            )}

            <button
              className="btn btn-danger"
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

// const handleEditSubmit = () => {

//     if (!selectedData?.name) {
//         setError((prevError) => ({
//             ...prevError,
//             name: 'Topic name is Required',
//         }));
//         return
//     } else {
//         setError((prevError) => ({
//             ...prevError,
//             name: '',
//         }));
//     }

//     if (!selectedData?.short_description) {
//         setError((prevError) => ({
//             ...prevError,
//             short_description: 'Topic short description is Required',
//         }));
//         return
//     } else {
//         setError((prevError) => ({
//             ...prevError,
//             short_description: '',
//         }));
//     }

//     if (!selectedData?.description) {
//         setError((prevError) => ({
//             ...prevError,
//             description: 'Topic body description is Required',
//         }));
//         return
//     } else {
//         setError((prevError) => ({
//             ...prevError,
//             description: '',
//         }));
//     }

//     if (selectedData?.ismain && !(updateLogo || selectedData?.logo)) {
//         setError((prevError) => ({
//             ...prevError,
//             logo: 'Topic Icon is Required',
//         }));
//         return
//     } else {
//         setError((prevError) => ({
//             ...prevError,
//             logo: '',
//         }));
//     }

//     const formData = new FormData();
//     formData.append('id', selectedData?._id);
//     formData.append('name', selectedData?.name);
//     formData.append('short_description', selectedData?.short_description);
//     formData.append('description', selectedData?.description);
//     if (updateLogo) {
//         formData.append('image', selectedData.logo);
//     }
//     formData.append('categoryId', selectedData?.topic_categotyId ? selectedData?.topic_categotyId : undefined);
//     formData.append('main', selectedData?.ismain);
//     setLoader(true);
//     ExportApi.updateHelpTopic(formData).then((resp) => {

//         console.log(resp?.data);
//         const data = resp?.data;
//         if (data?.status == 201) {
//             toast.success(data?.message)
//             setCount(1)
//             getTopicData();
//             setShowModal(false);
//             setUpdateLogo()
//             setLoader(false);
//         } else {
//             setShowModal(false);
//             toast.error(data?.message)
//             setLoader(false);
//             setCount(1)
//         }
//     }).catch((err) => {
//         console.log(err);
//         setLoader(false);
//         setShowModal(false);
//     })
//     setCount(1)
// }
