import React, { useState, useEffect } from 'react';
import { Form, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import CryptoJS from 'crypto-js'; // Import crypto-js
import ExportApi from "../../api/ExportApi";

// Function to encrypt the API key
const encrypt = (text) => {
    if (!text || typeof text !== 'string' || text.length === 0) {
        console.error('Input text is undefined, not a string, or empty');
        return '';
    }

    const SECRET_KEY = '3JrY8l@#QwErTyUiOpAsDfGhJkLmN1';
    const hashedKey = CryptoJS.SHA256(SECRET_KEY).toString();
    const parsedKey = CryptoJS.enc.Hex.parse(hashedKey);

    const iv = CryptoJS.lib.WordArray.random(128 / 8); // 16 bytes IV

    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(text), parsedKey, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    // Return a concatenation of the IV and the truncated encrypted string
    const encryptedString = iv.toString() + ':' + encrypted.toString();
    return encryptedString;
};


// Function to decrypt the API key
const decrypt = (encryptedText) => {
    const parts = encryptedText.split(':');
    if (parts.length !== 2) {
        throw new Error('Invalid encrypted text format');
    }

    const iv = CryptoJS.enc.Hex.parse(parts[0]);
    const ciphertext = parts[1];

    const SECRET_KEY = '3JrY8l@#QwErTyUiOpAsDfGhJkLmN1';
    const hashedKey = CryptoJS.SHA256(SECRET_KEY).toString();
    const parsedKey = CryptoJS.enc.Hex.parse(hashedKey);

    const decrypted = CryptoJS.AES.decrypt(ciphertext, parsedKey, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
    });

    const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
    if (!decryptedText) {
        throw new Error('Decryption failed');
    }

    return decryptedText;
};

function ApiKeys() {
    const [sendgridkey, setSendGridKey] = useState('');
    const [isUpdatingGrid, setIsUpdatingGrid] = useState(false);
    const [tawkkey, setTawkKey] = useState('');
    const [tawkid, setTawkId] = useState('');
    const [isUpdatingTawk, setIsUpdatingTawk] = useState(false);
    const [shippokey, setShippoKey] = useState('');
    const [isUpdatingShippo, setIsUpdatingShippo] = useState(false);
    const [stripekey, setStripeKey] = useState('');
    const [isUpdatingStripe, setIsUpdatingStripe] = useState(false);
    const [awskeyid, setAwskeyId] = useState('');
    const [awskey, setAwskey] = useState('');
    const [bucketname, setAwsBucketName] = useState('');
    const [awsregion, setAwsRegion] = useState('');
    const [isUpdatingAws, setIsUpdatingAws] = useState(false);



    const fetchApiKey = async () => {
        try {
            const res = await ExportApi.getapikey();
         
            if (res?.data?.apiKeys) {

                const apiKeys = res.data.apiKeys;
                console.log(apiKeys,"apiKeys1235")

                // Iterate through the API keys and log them
                apiKeys.forEach(sendgridkey => {
                    // console.log(`Service: ${sendgridkey.service}`);
                    // console.log(`Key: ${sendgridkey.key}`);
                
                    // Check if the service is "SendGrid"
                    if (sendgridkey.service === "SendGrid") {
                        setSendGridKey(sendgridkey.key);  
                        setIsUpdatingGrid(true);
                        return;  
                    }
                    if (sendgridkey.service === "Tawk") {

                        setTawkKey(sendgridkey?.key); 
                        setTawkId(sendgridkey?.tawkId) 
                        setIsUpdatingTawk(true);
                        return;  
                    }
                    if (sendgridkey.service === "Shippo") {
                        setShippoKey(sendgridkey?.key);  
                        setIsUpdatingShippo(true);
                        return;  
                    } 
                    if (sendgridkey.service === "Stripe") {
                        setStripeKey(sendgridkey.key);  
                        setIsUpdatingStripe(true);
                        return;  
                    }
                    if (sendgridkey.service === "AWS") {
                        setAwskeyId(sendgridkey.key);  
                        setAwskey(sendgridkey.awsKeyId); 
                        setAwsBucketName(sendgridkey.bucketName); 
                        setAwsRegion(sendgridkey.region); 
                        setIsUpdatingAws(true);
                        return;  
                    }
                  
                     
                });
                // const decryptedKey = decrypt(res.data.sendgridkey);
              
            
            }
        } catch (error) {
            console.error('Error fetching API key:', error);
            toast.error('Error fetching API key');
        }
    };

    useEffect(() => {
        fetchApiKey();
    }, []);
 

    const handleInputChange = (e) => {
        setSendGridKey(e.target.value);
    };

    const handleInputChangeTawk = (e) => {
        setTawkKey(e.target.value);
    };
    const handleInputChangeTawkid = (e) => {
        setTawkId(e.target.value);
    };
    const handleInputChangeshippo = (e) => {
        setShippoKey(e.target.value);
    };
    const handleInputChangeStripe = (e) => {
        setStripeKey(e.target.value);
    };

    const InputChangeAwsKeyId = (e) => {
        setAwskeyId(e.target.value);
    };
    const InputChangeAwsKey = (e) => {
        setAwskey(e.target.value);
    };
    const InputChangeAwsBucketName = (e) => {
        setAwsBucketName(e.target.value);
    };
    const InputChangeAwsRegion = (e) => {
        setAwsRegion(e.target.value);
    };
     
    const handleSubmit = async (e) => {
        e.preventDefault();

        const encryptedApiKey = encrypt(sendgridkey);

        if (!encryptedApiKey) {
            toast.error('Failed to encrypt the API key');
            return;
        }

        try {
            let data;

            // Use ExportApi.updatekey for updating
            if (isUpdatingGrid) {
                
                data = await ExportApi.updatekey({ key: encryptedApiKey ,service:"SendGrid"});
            } else {
                data = await ExportApi.saveapikey({ key: encryptedApiKey ,service:"SendGrid"});
            }

            if (data?.data?.message === "API key saved successfully." || data?.data?.message === "API key updated successfully.") {
                toast.success(isUpdatingGrid ? 'API Key updated successfully!' : 'API Key added successfully!');
                setIsUpdatingGrid(false);
                await fetchApiKey();
            } else {
                toast.error(data?.data?.message || 'Error occurred');
            }
        } catch (error) {
            console.error('Error saving API key:', error);
            toast.error('Error saving API key');
        }
    };

    const handleSubmitTawk = async (e) => {
        e.preventDefault();
    
        const encryptedApiKey = encrypt(tawkkey);
        const encryptedTawkId = encrypt(tawkid); // Encrypt the Tawk ID
    
        if (!encryptedApiKey) {
            toast.error('Failed to encrypt the API key');
            return;
        }
    
        try {
            let data;
    
            // Prepare the data to send
            const payload = {
                key: encryptedApiKey,
                tawkId: encryptedTawkId, // Include the Tawk ID
                service: "Tawk"
            };
    
            // Use ExportApi.updatekey for updating
            if (isUpdatingTawk) {
                data = await ExportApi.updatekey(payload);
            } else {
                data = await ExportApi.saveapikey(payload);
            }
    
            if (data?.data?.message === "API key saved successfully." || data?.data?.message === "API key updated successfully.") {
                toast.success(isUpdatingTawk ? 'API Key updated successfully!' : 'API Key added successfully!');
                setIsUpdatingTawk(false);
                await fetchApiKey();
            } else {
                toast.error(data?.data?.message || 'Error occurred');
            }
        } catch (error) {
            console.error('Error saving API key:', error);
            toast.error('Error saving API key');
        }
    };
    

    const handleSubmitShippo = async (e) => {
        e.preventDefault();

        const encryptedApiKey = encrypt(shippokey);

        if (!encryptedApiKey) {
            toast.error('Failed to encrypt the API key');
            return;
        }

        try {
            let data;

            // Use ExportApi.updatekey for updating
            if (isUpdatingShippo) {
                
                data = await ExportApi.updatekey({ key: encryptedApiKey ,service:"Shippo"});
            } else {
                data = await ExportApi.saveapikey({ key: encryptedApiKey ,service:"Shippo"});
            }

            if (data?.data?.message === "API key saved successfully." || data?.data?.message === "API key updated successfully.") {
                toast.success(isUpdatingTawk ? 'API Key updated successfully!' : 'API Key added successfully!');
                setIsUpdatingShippo(false);
                await fetchApiKey();
            } else {
                toast.error(data?.data?.message || 'Error occurred');
            }
        } catch (error) {
            console.error('Error saving API key:', error);
            toast.error('Error saving API key');
        }
    };

    const handleSubmitStripe = async (e) => {
        e.preventDefault();

        const encryptedApiKey = encrypt(stripekey);

        if (!encryptedApiKey) {
            toast.error('Failed to encrypt the API key');
            return;
        }

        try {
            let data;

            // Use ExportApi.updatekey for updating
            if (isUpdatingStripe) {
                
                data = await ExportApi.updatekey({ key: encryptedApiKey ,service:"Stripe"});
            } else {
                data = await ExportApi.saveapikey({ key: encryptedApiKey ,service:"Stripe"});
            }

            if (data?.data?.message === "API key saved successfully." || data?.data?.message === "API key updated successfully.") {
                toast.success(isUpdatingTawk ? 'API Key updated successfully!' : 'API Key added successfully!');
                setIsUpdatingStripe(false);
                await fetchApiKey();
            } else {
                toast.error(data?.data?.message || 'Error occurred');
            }
        } catch (error) {
            console.error('Error saving API key:', error);
            toast.error('Error saving API key');
        }
    };

    const handleSubmitAws = async (e) => {
        e.preventDefault();
    
        // Encrypt the API keys and other sensitive data
        const encryptedKey = encrypt(awskey);
        const encryptedKeyId = encrypt(awskeyid);
        const encryptedBucketName = encrypt(bucketname);
        const encryptedRegion = encrypt(awsregion);
    
        if (!encryptedKey || !encryptedKeyId || !encryptedBucketName || !encryptedRegion) {
            toast.error('Failed to encrypt one or more API keys');
            return;
        }
    
        const apiKeyData = {
            key: encryptedKey,
            awsKeyId: encryptedKeyId, // Encrypted AWS Key ID
            service: 'AWS',
            bucketName: encryptedBucketName, // Encrypted Bucket Name
            region: encryptedRegion, // Encrypted AWS Region
        };
    
        try {
            let data;
            if (isUpdatingAws) {
                data = await ExportApi.updatekey(apiKeyData ); // Adjusted to send the correct structure
            } else {
                data = await ExportApi.saveapikey(apiKeyData); // Adjusted to send the correct structure
            }
    
            if (data?.data?.message === "API key saved successfully." || data?.data?.message === "API key updated successfully.") {
                toast.success(isUpdatingAws ? 'API Key updated successfully!' : 'API Key added successfully!');
                setIsUpdatingAws(false);
                await fetchApiKey();
            } else {
                toast.error(data?.data?.message || 'Error occurred');
            }
        } catch (error) {
            console.error('Error saving API key:', error);
            toast.error('Error saving API key');
        }
    };
    
    
    
    
    return (
        <div> 
            {/* sendgrid*/}
        <div className='mt-4'>
            <h5>{isUpdatingGrid ? 'Update SendGrid API Key' : 'Add SendGrid API Key'}</h5>
            <Form onSubmit={handleSubmit}>
                <Form.Group controlId="sendgridkey">
                    <Form.Control
                        type="password"
                        value={sendgridkey}
                        onChange={handleInputChange}
                        placeholder="Enter your API Key"
                        required
                    />
                </Form.Group>
                <div className='mt-4'> 
                <Button variant="primary" type="submit">
                    {isUpdatingGrid ? 'Update Key' : 'Add Key'}
                </Button>
                </div>
            </Form>
        </div>

        {/* Tawk */}
        <div className='mt-4'>
            <h5>{isUpdatingTawk ? 'Update Tawk API Key' : 'Add Tawk API Key'}</h5>
            <Form onSubmit={handleSubmitTawk}>
                <Form.Group controlId="Tawkkey">
                    <Form.Control
                        type="password"
                        value={tawkkey}
                        onChange={handleInputChangeTawk}
                        placeholder="Enter your Tawk Key"
                        required
                    />
                    <div className='mt-2'> 
                       <h5>TawkId</h5>
                     <Form.Control
                        type="password"
                        value={tawkid}
                        onChange={handleInputChangeTawkid}
                        placeholder="Enter your Tawk Id"
                        required
                    />
                    </div>
                </Form.Group>
                
                <div className='mt-4'> 
                <Button variant="primary" type="submit">
                    {isUpdatingTawk ? 'Update Key' : 'Add Key'}
                </Button>
                </div>
            </Form>
        </div>

        {/* Shippo */}
        <div className='mt-4'>
            <h5>{isUpdatingShippo ? 'Update Shippo API Key' : 'Add Shippo API Key'}</h5>
            <Form onSubmit={handleSubmitShippo}>
                <Form.Group controlId="Shippokey">
                    <Form.Control
                        type="password"
                        value={shippokey}
                        onChange={handleInputChangeshippo}
                        placeholder="Enter your Shippo Key"
                        required
                    />
                </Form.Group>
                <div className='mt-4'> 
                <Button variant="primary" type="submit">
                    {isUpdatingShippo ? 'Update Key' : 'Add Key'}
                </Button>
                </div>
            </Form>
        </div>

       {/* stripe */}
        <div className='mt-4'>
            <h5>{isUpdatingStripe ? 'Update Stripe API Key' : 'Add Stripe API Key'}</h5>
            <Form onSubmit={handleSubmitStripe}>
                <Form.Group controlId="Stripekey">
                    <Form.Control
                        type="password"
                        value={stripekey}
                        onChange={handleInputChangeStripe}
                        placeholder="Enter your Stripe Key"
                        required
                    />
                </Form.Group>
                <div className='mt-4'> 
                <Button variant="primary" type="submit">
                    {isUpdatingStripe ? 'Update Key' : 'Add Key'}
                </Button>
                </div>
            </Form>
        </div>

        {/* Aws */}
        <div className='mt-4'>
            <h5>{isUpdatingAws ? 'Update Aws API Keys' : 'Add Aws API Keys'}</h5>
           <div className='mt-4'> 
            <Form onSubmit={handleSubmitAws}>
                <Form.Group controlId="Awskey">
                  <h5>Aws Access Key Id   </h5>
                    <Form.Control
                        type="password"
                        value={awskeyid}
                        onChange={InputChangeAwsKeyId}
                        placeholder="Enter your Aws Key Id"
                        required
                    />
                       <div className='mt-2'> 
                       <h5>Aws Secret Access Key</h5>
                     <Form.Control
                        type="password"
                        value={awskey}
                        onChange={InputChangeAwsKey}
                        placeholder="Enter your Aws Key"
                        required
                    />
                    </div>
                    <div className='mt-2'> 
                       <h5>Aws Bucket Name</h5>
                     <Form.Control
                        type="password"
                        value={bucketname}
                        onChange={InputChangeAwsBucketName}
                        placeholder="Enter your Bucket Name"
                        required
                    />
                    </div>
                    <div className='mt-2'> 
                       <h5>Aws Region</h5>
                     <Form.Control
                        type="password"
                        value={awsregion}
                        onChange={InputChangeAwsRegion}
                        placeholder="Enter your Aws Region"
                        required
                    />
                    </div>
                </Form.Group>
                <div className='mt-4'> 
                <Button variant="primary" type="submit">
                    {isUpdatingAws ? 'Update Key' : 'Add Key'}
                </Button>
                </div>
            </Form>
            </div>
        </div>

        </div>
    );
}

export default ApiKeys;
