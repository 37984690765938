import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Form,
  Button,
  FormControl,
  Row,
} from "react-bootstrap";
import { BiPlusCircle, BiMinusCircle } from 'react-icons/bi';
import { toast } from 'react-toastify';
import ExportApi from "../../api/ExportApi";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  Font,
  Bold,
  Italic,
  Strikethrough,
  Subscript,
  Superscript,
  Underline,
  Link,
  List,
  BlockQuote,
  Heading,
  Image,
  MediaEmbed,
  Table,
  TableToolbar,
  Undo,
  TableCellProperties,
  TableProperties,
  ShiftEnter,
  PasteFromOffice,
  Clipboard,
  Essentials, 
  Alignment,
  PasteFromMarkdownExperimental 
} from "ckeditor5";
import { useNavigate } from "react-router-dom";
function EditComponent() {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    heading: '',
    description: '',
    image: '',
    featured: false,
    unfeatured: false,
    categories: [],
    productVideos: []
  });
  const [inputList, setInputList] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchNewsById = async () => {
      try {
        const response = await ExportApi.getnewsbyslug(id);
        console.log(response,"myimgdata")
        const newsdata = response?.data?.data;
        setFormData({
          heading: newsdata?.heading,
          description: newsdata?.description,
          image: newsdata?.image,
          featured: newsdata?.featured,
          unfeatured: !newsdata?.featured,
          categories: newsdata?.categories?.map(category => ({
            name: category?.name,
            _id: category?._id,
            checked: true,
            subcategories: category?.subcategories?.map(subcategory => ({
              name: subcategory?.name,
              _id: subcategory?._id,
              checked: true
            }))
          })),
        });
        setInputList(newsdata?.embeddedVideoLinks);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching news:', error);
      }
    };

    fetchNewsById();
  }, [id]);

  const handleChange = (e) => {
    const { name, type, files } = e.target;

    if (type === "file") {
      const imageFile = files[0];

      const allowedTypes = ["image/jpeg", "image/png", "image/webp"];
      if (!allowedTypes.includes(imageFile.type)) {
        alert("Only jpeg, png, and webp files are supported.");
        return;
      }

      const maxSize = 1 * 1024 * 1024; // 1 MB
      if (imageFile.size > maxSize) {
        alert("File size exceeds 1 MB limit.");
        return;
      }

      setSelectedImage(imageFile);
      setFormData((prevState) => ({
        ...prevState,
        image: imageFile,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: e.target.value,
      }));
    }
  };

  const handleChangeFeatured = (e) => {
    const { checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      featured: checked,
      unfeatured: !checked,
    }));
  };

  const handleChangeProductVideos = (e, index) => {
    const { value } = e.target;
    const list = [...inputList];
    list[index] = value;
    setInputList(list);
    setFormData((prevState) => ({
      ...prevState,
      productVideos: list,
    }));
  };

  const handleAddClick = () => {
    setInputList([...inputList, ""]);
  };

  const handleRemoveClick = (index) => {
    if (inputList.length === 1) return;
    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
    setFormData((prevState) => ({
      ...prevState,
      productVideos: list,
    }));
  };

  const handleSaveChanges = async () => {
    console.log("handleSaveChanges >> hit");
    setIsLoading(true);
    try {
      const newsData = new FormData();
      newsData.append("heading", formData.heading);
      newsData.append("description", formData.description);
      newsData.append("image", selectedImage);
      newsData.append("unfeatured", formData.unfeatured);
      newsData.append("featured", formData.featured);
      newsData.append("imageid", id);
  
      // Ensure productVideos is an array before using forEach
      if (Array.isArray(formData.productVideos)) {
        formData.productVideos.forEach((video) => {
          newsData.append("embeddedVideoLinks", video);
        });
      }
  
      const categoriesString = JSON.stringify(formData.categories);
      newsData.append("allCategories", categoriesString);
          console.log(id,"newsslug");
      const response = await ExportApi.updateNewsbyid(id, newsData);
  
      if (response?.data?.status === false) {
        alert(response?.data?.message);
        
        setIsLoading(false);
      } else {
 
        toast.success(response?.data?.message);
        setTimeout(() => {
          navigate("/adminnewslist");
        }, 1000);
      
      }
    } catch (error) {
      console.error("Error updating news:", error);
      toast.error(error?.response?.data?.message);
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return (
      <div className="loader-icon" style={{ marginBlock: "80px" }}>
        <i className="fa-solid fa-spinner fa-spin-pulse"></i>
      </div>
    );
  }

  return (
    <div>
      <div className="mt-4"> 
        <h2>Edit News</h2>
      </div>
      <Form>
        <Form.Group controlId="heading">
          <Form.Label>Heading</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter news heading"
            name="heading"
            value={formData.heading}
            onChange={handleChange}
          />
        </Form.Group>
        <Form.Group controlId="featured" className="mb-3">
          <Form.Check
            type="checkbox"
            label="Featured"
            checked={formData.featured}
            onChange={handleChangeFeatured}
          />
        </Form.Group>
        <Form.Group controlId="categories" className="mb-3">
          <Form.Label className="fw-bold fs-6">Categories</Form.Label>
          {formData.categories.map((category, categoryIndex) => (
            <div key={categoryIndex}>
              <FormControl
                type="text"
                placeholder="Enter category name"
                value={category.name}
                onChange={(e) => {
                  const updatedCategories = [...formData.categories];
                  updatedCategories[categoryIndex].name = e.target.value;
                  setFormData((prevState) => ({
                    ...prevState,
                    categories: updatedCategories,
                  }));
                }}
              />
              {category.subcategories.map((subcategory, subcategoryIndex) => (
                <div key={subcategoryIndex}>
                  <FormControl
                    type="text"
                    placeholder="Enter subcategory name"
                    value={subcategory.name}
                    onChange={(e) => {
                      const updatedCategories = [...formData.categories];
                      updatedCategories[categoryIndex].subcategories[subcategoryIndex].name = e.target.value;
                      setFormData((prevState) => ({
                        ...prevState,
                        categories: updatedCategories,
                      }));
                    }}
                  />
                </div>
              ))}
              <br />
            </div>
          ))}
        </Form.Group>
        <Form.Group controlId="image">
      <Form.Label className="fw-bold fs-6">Image</Form.Label>
      <Form.Control
        type="file"
        name="image"
        accept="image/*"
        onChange={handleChange}
      />
      {selectedImage && (
        <img
          src={URL.createObjectURL(selectedImage)}
          alt="Selected Image"
          style={{ marginTop: "10px", width: "300px", height: "300px" }}
        />
      )}
      { formData.image && !selectedImage && (
        <div className="custom-div-sec">
          <img
            src={formData.image}
            alt="News Image"
            style={{
              marginTop: "10px",
              width: "300px",
              height: "300px",
            }}
          />
        </div>
      )}
    </Form.Group>
        <Form.Group controlId="description">
              <Form.Label className="fw-bold fs-6">Description</Form.Label>
              {/* <div className={"customckeditorr"}>  
{              console.log("Before set:", formData.description)}
              <CKEditor
                editor={ClassicEditor}
                data={formData.description}
     
            
                config={{
                  plugins: [
                    Font,
                    Bold,
                    Italic,
                    Link,
                    Strikethrough,
                    Subscript,
                    Underline,
                    List,
                    BlockQuote,
                    Heading,
                    Image,
                    MediaEmbed,
                    Table,
                    TableProperties,
                    TableCellProperties,
                    TableToolbar,
                    Undo,
                    ShiftEnter,
                    PasteFromOffice,
                    Clipboard,
                    Essentials, 
                    PasteFromMarkdownExperimental
                  ],
             
                  toolbar: {
                    items: [
                      "heading",
                      'paragraph', 'heading1', 'heading2', 'heading3', 'heading4',
                      "|",
                      "bold",
                      "italic",
                      "Underline",
                      "Superscript",
                      "Subscript",
                      "Strikethrough",
                      "fontSize",
                      "fontColor",
                      "fontBackgroundColor",
                      "bulletedList",
                      "numberedList",
                      "|",
                      "indent",
                      "outdent",
                      "|",
                      "blockQuote",
                      "insertTable",
                      "|",
                      "undo",
                      "redo",
                      "mediaEmbed",
                      "imageUpload",
                      "link",
                      "fontFamily",
                      "tableColumn",
                      "tableRow",
                      "mergeTableCells",
                      "alignment",
                      "tableProperties",
                      "tableCellProperties",
                      
                    ],
                  },
                  heading: {
                    options: [
                      { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                      { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                      { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                      { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                      { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' }
                  ],
                },
                  mediaEmbed: {
                    previewsInData: true,
                  },
                  image: {
                    toolbar: [
                      "imageTextAlternative",
                      "|",
                      "imageStyle:full",
                      "imageStyle:side",
                    ],
                    styles: ["alignLeft", "alignCenter", "alignRight"],
                  },
                  fontFamily: {
                    options: ["Helvetica", "Open Sans"],
                  },
                  table: {
                    contentToolbar: [
                      "tableColumn",
                      "tableRow",
                      "mergeTableCells",
                      "alignment",
                      "tableProperties",
                      "tableCellProperties",
                    ],
                  },
                }}
                
                onChange={(event, editor) => {
                  const descriptionData = editor.getData();
                  console.log(descriptionData, "descriptionData123");
                  setFormData((prevState) => ({
                    ...prevState, // Spread previous state
                    description: descriptionData, // Update only the description
                  }));
                }}
                
              />
              </div> */}
            <div className={"customckeditorr"}>
  {console.log("Before set:", formData.description)}
  <CKEditor
    editor={ClassicEditor}
    data={formData.description || ""} // Default to empty string
    config={{
      plugins: [
        Font,
        Bold,
        Italic,
        Link,
        Strikethrough,
        Subscript,
        Underline,
        List,
        BlockQuote,
        Heading,
        Image,
        MediaEmbed,
        Table,
        TableProperties,
        TableCellProperties,
        TableToolbar,
        Undo,
        ShiftEnter,
        PasteFromOffice,
        Clipboard,
        Essentials,
        Alignment,
        PasteFromMarkdownExperimental
      ],
      toolbar: {
        items: [
          "heading",
          'paragraph', 'heading1', 'heading2', 'heading3', 'heading4',
          "|",
          "bold",
          "italic",
          "Underline",
          "Superscript",
          "Subscript",
          "Strikethrough",
          "fontSize",
          "fontColor",
          "fontBackgroundColor",
          "bulletedList",
          "numberedList",
          "alignment",
          "|",
          "indent",
          "outdent",
          "|",
          "blockQuote",
          "insertTable",
          "|",
          "undo",
          "redo",
          "mediaEmbed",
          "imageUpload",
          "link",
          "fontFamily",
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "alignment",
          "tableProperties",
          "tableCellProperties",
        ],
      },
      heading: {
        options: [
          { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
          { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
          { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
          { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
          { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' }
        ],
      },
      mediaEmbed: {
        previewsInData: true,
      },
      image: {
        toolbar: [
          "imageTextAlternative",
          "|",
          "imageStyle:full",
          "imageStyle:side",
        ],
        styles: ["alignLeft", "alignCenter", "alignRight"],
      },
      fontFamily: {
        options: ["Helvetica", "Open Sans"],
      },
      table: {
        contentToolbar: [
          "tableColumn",
          "tableRow",
          "mergeTableCells",
          "alignment",
          "tableProperties",
          "tableCellProperties",
        ],
      },
      alignment: {
        options: [ 'left', 'right','center','justify' ]
    },
    }}
    onChange={(event, editor) => {
      const descriptionData = editor.getData();
      console.log(descriptionData, "descriptionData123"); // Check the content being captured
      setFormData((prevState) => ({
        ...prevState,
        description: descriptionData, // Ensure the full description is set
      }));
    }}
  />
</div>


            </Form.Group>
        <Form.Group controlId="productVideos" className="mb-3">
          <Form.Label className="fw-bold fs-6">Product Videos Links</Form.Label>
          <Row className="g-3">
            {inputList.map((link, i) => (
              <div className="d-flex mb-3 justify-content-between" key={i}>
                <FormControl
                  placeholder="Embedded Video Link"
                  value={link}
                  onChange={(e) => handleChangeProductVideos(e, i)}
                />
                {i === inputList.length - 1 && (
                  <button
                    type="button"
                    className="butt bg-none border-0"
                    onClick={handleAddClick}
                  >
                    <BiPlusCircle style={{ fontSize: "30px" }} />
                  </button>
                )}
                {i !== 0 && (
                  <button
                    type="button"
                    className="bg-none border-0 ms-2"
                    onClick={() => handleRemoveClick(i)}
                  >
                    <BiMinusCircle style={{ fontSize: "30px" }} />
                  </button>
                )}
              </div>
            ))}
          </Row>
        </Form.Group>
        <div className="d-flex justify-content-center">
          <Button variant="primary" onClick={handleSaveChanges}>
            {isLoading ? "Updating..." : "Update"}
          </Button>
        </div>
        <br></br>
        <br></br>
        <br></br>
        <br></br>
       
      </Form>
    </div>
  );
}

export default EditComponent;
