import React, { useEffect, useCallback, useState } from "react";
import {
  Form,
  Button,
  FormLabel,
  FormSelect,
  Row,
  FormControl,
  FormCheck,
  FormGroup,
  Alert,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import ExportApi from "../../api/ExportApi";
import { useNavigate } from "react-router-dom";
import { BiPlusCircle } from "react-icons/bi";
import { BiMinusCircle } from "react-icons/bi";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import {
  ClassicEditor,
  Font,
  Bold,
  Italic,
  Strikethrough,
  Subscript,
  Superscript,
  Underline,
  Link,
  List,
  BlockQuote,
  Heading,
  Image,
  MediaEmbed,
  Table,
  TableToolbar,
  Undo,
  TableCellProperties,
  TableProperties,
  ShiftEnter,
  PasteFromOffice,
  Clipboard,
  Essentials, 
  Alignment, 
  PasteFromMarkdownExperimental
} from "ckeditor5";
// import { ImportWord } from 'ckeditor5-premium-features';
// import { SlashCommand } from 'ckeditor5-premium-features';
// import 'ckeditor5-premium-features/ckeditor5-premium-features.css';

import { useDropzone } from "react-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton } from "@mui/material";

export default function Adminnews() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    setError,
  } = useForm();
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [newsList, setNewsList] = useState([]);
  const [inputList, setInputList] = useState([""]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [categories, setCategories] = useState([
    { name: "", subcategories: [{ name: "" }] },
  ]);
  const [selectedType, setSelectedType] = useState([
    { label: "Unfeatured", value: "unfeatured" },
  ]);
  const [unfeatured, setUnfeatured] = useState(true);
  const [featured, setFeatured] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});


  const imageUrl = process.env.REACT_APP_IMAGEBASEURL;

  const handleUnfeaturedChange = () => {
    setUnfeatured(!unfeatured);
    setFeatured(false);
  };

  // const handleFeaturedChange = (e) => {
  //   console.log(e.target.checked,"logdataa");
  //   setFeatured(!featured);
  //   setUnfeatured(false);
  // };

  const handleFeaturedChange = (e) => {
    // Log the checked status
    console.log(e.target.checked, "logdataa");

    // Update state based on the checked status
    if (e.target.checked) {
      setFeatured(true);
      setUnfeatured(false);
    } else {
      setFeatured(false);
      setUnfeatured(true);
    }
  };

  const handleTypeChange = (selected) => {
    setSelectedType(selected);
    setValue("type", selected ? selected.map((option) => option.value) : []);
  };

  const handleCategoryChange1 = (index) => {
    const updatedCategories = [...categories];
    updatedCategories[index].checked = !updatedCategories[index].checked;
    setCategories(updatedCategories);
  };

  const handleSubcategoryChange = (categoryIndex, subcategoryIndex) => {
    const updatedCategories = [...categories];
    updatedCategories[categoryIndex].subcategories[subcategoryIndex].checked =
      !updatedCategories[categoryIndex].subcategories[subcategoryIndex].checked;
    setCategories(updatedCategories);
  };

  const addCategory = () => {
    const updatedCategories = [...categories];
    updatedCategories.push({ name: "", subcategories: [{ name: "" }] });
    setCategories(updatedCategories);
  };

  const addSubcategory = (categoryIndex) => {
    const updatedCategories = [...categories];
    updatedCategories[categoryIndex].subcategories.push({ name: "" });
    setCategories(updatedCategories);
  };

  const removeCategory = (index) => {
    const updatedCategories = [...categories];
    updatedCategories.splice(index, 1);
    setCategories(updatedCategories);
  };

  const removeSubcategory = (categoryIndex, subcategoryIndex) => {
    const updatedCategories = [...categories];
    updatedCategories[categoryIndex].subcategories.splice(subcategoryIndex, 1);
    setCategories(updatedCategories);
  };

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      const rejectedFile = rejectedFiles[0];
      console.log(rejectedFile, "rejectedFiles11");
      console.log(rejectedFile.errors[0].message, "rejectedFiles12");

      if (
        rejectedFile?.errors[0]?.message == "File is larger than 1048576 bytes"
      ) {
        alert("Please upload an image file with a maximum size of 1MB.");
      } else if (
        rejectedFile?.errors[0]?.message ==
        "File type must be image/jpeg,image/png,image/webp"
      ) {
        alert("Please upload an image file with jpeg,png and webp format.");
      }
    } else {
      const acceptedFile = acceptedFiles[0];
      if (acceptedFile) {
        setSelectedImage(acceptedFile);
      }
    }
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/jpeg": [],
      "image/png": [],
      "image/webp": [],
    },
    maxSize: 1024 * 1024,
  });

  const navigate = useNavigate();
  const fetchNewsList = async () => {
    try {
      const response = await ExportApi.getAllnews();
      console.log(response, "respons>>>>>>123");
      setNewsList(response.data.data);
    } catch (error) {
      console.error("Error fetching news list:", error);
    }
  };

  //   const validateCategories = () => {
  //     const hasSelectedCategory = categories.some(
  //       (category) => category.checked
  //     );
  //     const hasSelectedSubcategory = categories.some((category) =>
  //       category.subcategories.some((subcategory) => subcategory.checked)
  //     );

  //     if (!hasSelectedCategory) {
  //       return { isValid: false, message: "Please select a category." };
  //     } else if (!hasSelectedSubcategory) {
  //       return { isValid: false, message: "Please select a subcategory." };
  //     }

  //     return { isValid: true, message: "" };
  //   };

  const onSubmit = async (data) => {
    // const validation = validateCategories();

    // if (!validation.isValid) {
    //   setValidationErrors({
    //     subcategory: validation.message,
    //   });
    //   return;
    // }
    try {
      setButtonDisabled(true);
      const adminId = JSON.parse(localStorage.getItem("admin")).id;

      const formDataToSend = new FormData();
      formDataToSend.append("heading", data.heading);
      formDataToSend.append("description", data.description);
      formDataToSend.append("createdBy", adminId);
      formDataToSend.append("image", selectedImage);

      formDataToSend.append("unfeatured", unfeatured);

      formDataToSend.append("featured", featured);

      if (Array.isArray(categories)) {
        categories.forEach((category) => {
          formDataToSend.append("allCategories", JSON.stringify(category));
        });
      }

      if (Array.isArray(inputList)) {
        inputList.forEach((videoLink, index) => {
          formDataToSend.append(`embeddedVideoLinks`, videoLink);
        });
      }

      const resp = await ExportApi.postNews(formDataToSend);
      console.log(resp, "Sent form data>>>>>>>>>>resp");
      if (resp.status === 200) {
        fetchNewsList();
        navigate("/adminnewslist");
        toast.success("News added successfully!");
      } else {
        console.error(resp, "Error sending form data");
        toast.error(resp?.data?.message);
        setButtonDisabled(false);
      }
    } catch (error) {
      console.error(error, "error123");
      toast.error(error.message);
      setButtonDisabled(false);
    }
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index] = value;
    setInputList(list);
  };
  const handleCategoryChange = (e, index) => {
    const updatedCategories = [...categories];
    updatedCategories[index] = e.target.value;
    setCategories(updatedCategories);
  };
  const handleAddClick = () => {
    setInputList([...inputList, ""]);
  };

  const handleRemoveClick = (index) => {
    if (index === 0) {
      return;
    }

    const list = [...inputList];
    list.splice(index, 1);
    setInputList(list);
  };
  const handleDeleteImage = () => {
    setSelectedImage(null);
  };

  return (
    <>
      <Form onSubmit={handleSubmit(onSubmit)} className="py-5">
        <h2 className="mb-4">Add News</h2>
        <Form.Group controlId="heading" className="mb-3">
          <Form.Label className="fw-bold fs-6">Heading</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter news heading"
            {...register("heading")}
          />
        </Form.Group>
        <Form.Group controlId="unfeatured" className="mb-3">
          <Form.Label className="fw-bold fs-6">Type</Form.Label>

          <div className="mb-3">
            <Form.Check
              type="checkbox"
              label="Featured"
              {...register("type")}
              checked={featured}
              onChange={handleFeaturedChange}
              id="featured"
              name="type"
            />
          </div>
          {/* <div>
            <Form.Check
              type="checkbox"
              label="Unfeatured"
              {...register("type")}
              checked={unfeatured}
              onChange={handleUnfeaturedChange}
              id="unfeatured"
              name="type"
            />
          </div> */}
        </Form.Group>

        <Form.Group controlId="categories" className="mb-3">
          <Form.Label className="fw-bold fs-6">Categories</Form.Label>
          {validationErrors.category && (
            <Alert variant="danger">{validationErrors.category}</Alert>
          )}
          {categories.map((category, categoryIndex) => (
            <div key={categoryIndex}>
              <FormControl
                type="text"
                placeholder="Enter category name"
                value={category.name}
                onChange={(e) => {
                  const updatedCategories = [...categories];
                  updatedCategories[categoryIndex].name = e.target.value;
                  setCategories(updatedCategories);
                }}
              />
              <FormCheck
                type="checkbox"
                id="category"
                label="Category"
                checked={category.checked}
                onChange={() => handleCategoryChange1(categoryIndex)}
              />
              {category.checked && (
                <FormGroup>
                  {validationErrors.subcategory && (
                    <Alert variant="danger">
                      {validationErrors.subcategory}
                    </Alert>
                  )}
                  {category.subcategories.map(
                    (subcategory, subcategoryIndex) => (
                      <div
                        className="d-flex mb-3 align-items-center"
                        key={subcategoryIndex}
                      >
                        <FormControl
                          type="text"
                          placeholder="Enter subcategory name"
                          value={subcategory.name}
                          onChange={(e) => {
                            const updatedCategories = [...categories];
                            updatedCategories[categoryIndex].subcategories[
                              subcategoryIndex
                            ].name = e.target.value;
                            setCategories(updatedCategories);
                          }}
                        />
                        <FormCheck
                          type="checkbox"
                          id="subcategory"
                          label="Subcategory"
                          checked={subcategory.checked}
                          onChange={() =>
                            handleSubcategoryChange(
                              categoryIndex,
                              subcategoryIndex
                            )
                          }
                        />
                        <button
                          type="button"
                          className="btn btn-danger ms-2"
                          onClick={() =>
                            removeSubcategory(categoryIndex, subcategoryIndex)
                          }
                        >
                          Remove Subcategory
                        </button>
                      </div>
                    )
                  )}
                  <br />

                  {category.subcategories.length === 0 && (
                    <Button
                      variant="primary"
                      onClick={() => addSubcategory(categoryIndex)}
                    >
                      Add Subcategory
                    </Button>
                  )}
                </FormGroup>
              )}
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => removeCategory(categoryIndex)}
              >
                Remove Category
              </button>
            </div>
          ))}
          {categories.length === 0 && (
            <Button variant="primary" onClick={addCategory}>
              Add Category
            </Button>
          )}
        </Form.Group>

        <Form.Group controlId="image" className="mb-3">
          <Form.Label className="fw-bold fs-6">News Image</Form.Label>
          <div
            {...getRootProps()}
            className="d-flex justify-content-center flex-column align-items-center border px-3 py-5"
          >
            <input {...getInputProps()} accept="image/*" />
            <span className="">Support : Jpeg,Png,webp</span>
            <span className="">Maximum image size supported: 1 MB</span>
            <p>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                className="bi bi-image me-2"
                viewBox="0 0 16 16"
              >
                <path d="M6.002 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"></path>
                <path d="M2.002 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V3a2 2 0 0 0-2-2h-12zm12 1a1 1 0 0 1 1 1v6.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71-2.66-1.772a.5.5 0 0 0-.63.062L1.002 12V3a1 1 0 0 1 1-1h12z"></path>
              </svg>
              Drag and drop your image here or click to select.
            </p>
          </div>
          {selectedImage && (
            <div className="mt-3">
              <h5 className="fw-bold">Selected Image</h5>
              <div className="custom-div-sec">
                <div className="news-image">
                  <img
                    src={URL.createObjectURL(selectedImage)}
                    alt="Selected"
                    className="show_multi_images"
                    style={{ marginTop: "10px", width: "100%", height: "100%" }}
                  />{" "}
                  <div className="icon_div custom-del-ic">
                    <IconButton color="error" onClick={handleDeleteImage}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Form.Group>

        <Form.Group controlId="description" className="mb-3">
          <Form.Label className="fw-bold fs-6">Description</Form.Label>
          <div className={"customckeditorr"}>
            <CKEditor
              editor={ClassicEditor}
              data=""
              config={{
                plugins: [
                  Font,
                  Bold,
                  Italic,
                  Link,
                  Strikethrough,
                  Subscript,
                  Underline,
                  List,
                  BlockQuote,
                  Heading,
                  Image,
                  MediaEmbed,
                  Table,
                  TableProperties,
                  TableCellProperties,
                  TableToolbar,
                  Undo,
                  ShiftEnter,
                  PasteFromOffice,
                  Clipboard,
                  Essentials, 
                  Alignment,
                  PasteFromMarkdownExperimental
                ],
           
                toolbar: {
                  items: [
                    "heading",
                    'paragraph', 'heading1', 'heading2', 'heading3', 'heading4',
                    "|",
                    "bold",
                    "italic",
                    "Underline",
                    "Superscript",
                    "Subscript",
                    "Strikethrough",
                    "fontSize",
                    "fontColor",
                    "fontBackgroundColor",
                    "bulletedList",
                    "numberedList",
                    "alignment",
                    "|",
                    "indent",
                    "outdent",
                    "|",
                    "blockQuote",
                    "insertTable",
                    "|",
                    "undo",
                    "redo",
                    "mediaEmbed",
                    "imageUpload",
                    "link",
                    "fontFamily",
                    "tableColumn",
                    "tableRow",
                    "mergeTableCells",
                    "alignment",
                    "tableProperties",
                    "tableCellProperties",
                    
                  ],
                },
                heading: {
                  options: [
                    { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                    { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                    { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                    { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                    { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' }
                ],
              },
                mediaEmbed: {
                  previewsInData: true,
                },
                image: {
                  toolbar: [
                    "imageTextAlternative",
                    "|",
                    "imageStyle:full",
                    "imageStyle:side",
                  ],
                  styles: ["alignLeft", "alignCenter", "alignRight"],
                },
                fontFamily: {
                  options: ["Helvetica", "Open Sans"],
                },
                table: {
                  contentToolbar: [
                    "tableColumn",
                    "tableRow",
                    "mergeTableCells",
                    "alignment",
                    "tableProperties",
                    "tableCellProperties",
                  ],
                },
                alignment: {
                  options: [ 'left', 'right','center','justify']
              },
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                setValue("description", data);
              }}
            />
          </div>
          {errors.description && (
            <p className="form-error">{errors.description.message}</p>
          )}
        </Form.Group>

        <Form.Group controlId="productVideos" className="mb-3">
          <FormLabel className="fw-bold fs-6">Product Videos Links</FormLabel>
          <Row className="g-3">
            {inputList.map((x, i) => {
              if (true) {
                return (
                  <div className="d-flex mb-3 justify-content-between">
                    <FormControl
                      name={`embeddedVideoLink${i + 1}`}
                      placeholder="Embedded Video Link"
                      value={x}
                      autocomplete="off"
                      style={{}}
                      onChange={(e) => handleInputChange(e, i)}
                    />

                    <button
                      type="button"
                      className="butt bg-none border-0"
                      onClick={handleAddClick}
                    >
                      <BiPlusCircle style={{ fontSize: "30px" }} />
                    </button>

                    <button
                      type="button"
                      className="bg-none border-0 ms-2"
                      onClick={() => handleRemoveClick(i)}
                    >
                      <BiMinusCircle
                        style={{
                          fontSize: "30px",
                        }}
                      />
                    </button>
                  </div>
                );
              }
            })}
          </Row>
        </Form.Group>

        <Button
          variant="primary"
          type="submit"
          className="mt-4"
          disabled={isButtonDisabled}
        >
          {isButtonDisabled ? "Adding..." : "Add News"}
        </Button>

        {/* {validationErrors.category && (
            <p style={{color:"red"}}>{validationErrors.category}</p>
          )}
        {validationErrors.subcategory && (
                    <p style={{color:"red"}}>
                      {validationErrors.subcategory}
                    </p>
                  )} */}
      </Form>
    </>
  );
}
